<template>
  <div>
    <nav
      class="fr-nav"
      role="navigation"
      aria-label="Menu principal"
      tabindex="-1"
    >
      <ul class="fr-nav__list">
        <li class="fr-nav__item">
          <a href="https://mes-aides.gouv.fr" class="fr-nav__link">Accueil</a>
        </li>
        <li class="fr-nav__item">
          <a
            href="https://mes-aides.gouv.fr/questions-frequentes"
            class="fr-nav__link"
            >Questions fréquentes</a
          >
        </li>
      </ul>
    </nav>
  </div>
</template>
