"use strict"

export const situationsFamiliales = [
  {
    value: "marie", // Enum value 1 in OpenFisca
    label: "Marié ou mariée",
  },
  {
    value: "pacse", // Enum value 5 in OpenFisca
    label: "Pacsé ou pacsée",
  },
  {
    value: "celibataire", // Enum value 2 in OpenFisca
    label: "En union libre",
  },
]
